<script setup lang="ts">
import type { Image } from "~/utils/types";

const props = defineProps<{
  image: Image
  float?: Image
  divider?: boolean
}>()

const hasFloat = computed(() => !!props.float)
</script>

<template>
  <div
    class="relative rounded overflow-hidden flex flex-col shadow-lg border border-gray-200"
  >
    <div
      :data-float="hasFloat"
      class="relative overflow-hidden flex min-h-32 laptop:data-[float=true]:grid laptop:data-[float]:grid-cols-[1fr_28em]"
    >
      <NuxtPicture
        :src="props.image.src"
        :alt="props.image.alt"
        :title="props.image.title"
        loading="lazy"
        class="absolute inset-0"
        sizes="640px laptop:960px desktop:1078px"
        :img-attrs="{
          class: 'w-full h-full object-cover object-center'
        }"
      />
      <div class="relative flex-1 text-white mx-8 laptop:ml-16 my-4 flex">
        <slot name="header" />
      </div>
    </div>
    <span
      v-show="props.divider"
      class="bg-primary h-[10px] w-full"
    />
    <slot :hasFloat="hasFloat" />
    <NuxtPicture
      v-if="props.float"
      :src="props.float.src"
      :alt="props.float.alt"
      class="absolute right-8 top-8 my-auto w-[28em] max-h-[14em] hidden laptop:flex"
      :img-attrs="{
        class: 'w-[28em] max-h-[14em] object-center object-contain'
      }"
    />
  </div>
</template>
